import { useEffect } from "react"
import { navigate } from "@reach/router"

export default function Whitepaper() {

  useEffect(() => {
    navigate('https://siltawhitepaper.s3.amazonaws.com/Silta_whitepaperV1.02.pdf');
  }, []);

  return null
}